<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex justify-end gap-2">
        <base-select
          :shadow="false"
          v-model="filter.is_shipped"
          @change="loadStockMovements"
          :options="[
            {
              label: 'Semua',
              value: null,
            },
            {
              label: 'Terkirim',
              value: true,
            },
            {
              label: 'Belum Dikirim',
              value: false,
            }
          ]"
        />
        <base-input
          :shadow="false"
          placeholder="Cari Kode atau Nama"
          type="search"
          debounce
          @native-input="loadStockMovements"
          v-model="filter.search"
        />
      </div>
      <datatable
        :columns="columns"
        :total="stockMovements.meta.page.total"
        :perPage="stockMovements.meta.page.perPage"
        :currentPage="stockMovements.meta.page.currentPage"
        :meta="stockMovements.meta"
        cursor
        @pagechanged="onChangePage"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="stockMovement in stockMovements.data"
            :key="stockMovement.id"
            :class="[classes.tr, 'cursor-pointer bg-white hover:bg-green-100']"
            @click="onClickDetail(stockMovement)"
          >
            <td :class="[classes.td]">
              <span class="block font-bold text-gray-900">{{
                stockMovement.attributes.code
              }}</span>
              <span class="text-xs text-gray-500">{{
                getSingleIncluded(
                  stockMovements,
                  stockMovement.relationships['order'].data.id
                ).attributes.origin_code
              }} / {{
                getSingleIncluded(
                  stockMovements,
                  stockMovement.relationships['order'].data.id
                ).attributes.destination_code
              }}</span>
            </td>
            <td :class="[classes.td]">{{
                getSingleIncluded(
                  stockMovements,
                  stockMovement.relationships['destination-office'].data.id
                ).attributes.code
              }}
            </td>
            <td :class="[classes.td]">{{
                stockMovement.attributes.updatedAt | formatDate
              }}
            </td>
            <td :class="[classes.td]">{{
                stockMovement.attributes.packet_qty | toCurrency
              }}
            </td>
            <td :class="[classes.td]">{{
                stockMovement.attributes.product_qty | toCurrency
              }}
            </td>
            <td :class="[classes.td]">
              <base-badge :color="stockMovement.attributes.is_shipped ? 'green' : 'red'">{{ stockMovement.attributes.is_shipped ? 'Terkirim' : 'Belum Dikirim' }}</base-badge>
            </td>
          </tr>
        </template>
      </datatable>
    </div>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex'

export default {
  mixins: [requestMixin],
  components: {
  },
  data() {
    return {
      loading: false,
      stockMovements: {
        data: [],
        meta: {
          page: {},
        },
      },
      filter: {
        search: null,
        is_shipped: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser'
    }),
    columns() {
      return [
        { id: 'code', name: 'Nomor Pengemasan' },
        { id: 'destination_office_code', name: `Kode ${this.destinationOfficeLabel}` },
        { id: 'updated_at', name: 'Tanggal Pengemasan' },
        { id: 'packet_qty', name: 'Total Koli' },
        { id: 'product_qty', name: 'Total Barang' },
        { id: 'status', name: 'Status' },
      ];
    },
  },
  methods: {
    onChangePage(page) {
      this.loadStockMovements({
        ...page,
      });
    },
    onClickDetail(simpleStockMovement) {
      this.$router.push({
        name: 'gudang.pengiriman.barang-ke-stockist-diambil.detail',
        params: {
          id: simpleStockMovement.relationships['stock-movement'].data.id
        }
      })
    },
    async loadStockMovements(params) {
      this.loading = true;

      const [res, error] = await this.request('/api/v1/stock-movements', {
        params: {
          'page[limit]': 5,
          'filter[destination_office_category_id]': 4,
          'filter[shipment_type]': 'pickup',
          'filter[origin_office_id]': this.me.office_id,
          'filter[search]': this.filter.search,
          'filter[is_shipped]': this.filter.is_shipped,
          'include': 'destination-office,stock-movement,order',
          'fields[simple-stock-movements]': 'code,updatedAt,product_qty,packet_qty,is_shipped,destination-office,stock-movement,order',
          'fields[offices]': 'code',
          'fields[stock-movements]': 'code',
          'fields[orders]': 'origin_code,destination_code',
          ...params,
        },
      });

      if (!error) {
        this.stockMovements = res;
      }

      this.loading = false;
    },
  },
  created() {
    this.loadStockMovements();
  },
};
</script>
