<template>
  <div>
    <base-modal
      :showing="visible"
      size="max-w-lg"
      @close="$emit('close')"
      @opened="onOpened"
    >
      <base-card
        title="Validasi Pengiriman"
        :with-style="false"
        description="Lengkapi form dibawah ini untuk memvalidasi pengiriman kemasan"
      >
        <form class="space-y-4 border-t pt-5" @submit.prevent="onSubmit">
          <base-input
            fullwidth
            inset
            type="text"
            label="Nama Kemasan"
            disabled
            :value="stockMovement.data.attributes.code"
          />
          <template
            v-if="stockMovement.data.attributes.shipment_type === 'pickup'"
          >
            <base-input
              fullwidth
              inset
              type="text"
              label="Diambil oleh"
              placeholder="Masukkan nama yang mengambil barang"
              v-model="form.taken_by"
            />
          </template>
          <template v-else>
            <base-input
              fullwidth
              inset
              type="text"
              label="Nomor Resi"
              placeholder="Masukkan nomor resi yang didapatkan dari kurir"
              v-model="form.airwaybill_number"
            />
          </template>
          <div class="grid grid-cols-2 gap-2">
            <base-button fullwidth>Kirim</base-button>
            <base-button
              type="button"
              fullwidth
              color="light"
              @click="$emit('close')"
              >Tutup</base-button
            >
          </div>
        </form>
      </base-card>
    </base-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import BaseModal from '@/components/base/BaseModal.vue';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: { BaseModal },
  props: {
    visible: Boolean,
    stockMovement: Object,
  },
  emits: ['close', 'sent'],
  data() {
    return {
      form: {
        taken_by: null,
        airwaybill_number: null,
      },
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    onOpened() {
      this.form.taken_by = null;
      this.form.airwaybill_number = null;
    },
    async onSubmit() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/stock-movements/${this.stockMovement.data.id}/-actions/send`,
        {
          method: 'patch',
          data: {
            data: {
              type: 'stock-movements',
              id: this.stockMovement.data.id,
              attributes: this.form,
            },
          },
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.$emit('sent');
      }

      this.loading = false;
    },
  },
};
</script>
